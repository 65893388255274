<template>
    <div>
        <div v-show="items.length === 0 && fetching === 0" class="text-xl pt-8 text-center dark:text-white">No Results Found</div>
        <div :class="containerClass">
            <transition-group name="move-unit">
                <div v-for="item, idx in items" :key="item.id ?? idx">
                    <slot :="{ item, idx }"></slot>
                </div>
            </transition-group>
        </div>
        <LoadingSpinner v-show="fetching > 0" />
        <div ref="infiniteScrollBottomDetector"></div>
    </div>
</template>

<script>
import { nextTick, onMounted, onUnmounted, ref } from "vue";
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
    name: "InfiniteScroller",
    components: { LoadingSpinner },
    props: {
        items: Array,
        fetchItems: Function,
        itemsAtEnd: Boolean,
        containerClass: { type: String, required: false, default: "" },
        maxDistanceToBottom: { type: Number, required: false, default: 500 },
    },
    setup(props) {
        const fetching = ref(0)
        const infiniteScrollBottomDetector = ref(null)

        const moreItemsRequired = () => {
            const bottom = infiniteScrollBottomDetector.value
            if (bottom == null) return false

            const windowHeight = window.innerHeight
            const windowDistanceFromTop = window.scrollY
            const elementDistanceFromTop = window.pageYOffset + bottom.getBoundingClientRect().top
            const screenDistanceToBottomDetector = elementDistanceFromTop - (windowDistanceFromTop + windowHeight)

            // console.log("windowHeight", windowHeight)
            // console.log("windowDistanceFromTop", windowDistanceFromTop)
            // console.log("elementDistanceFromTop", elementDistanceFromTop)
            // console.log("screenDistanceToBottomDetector", screenDistanceToBottomDetector)

            return screenDistanceToBottomDetector < props.maxDistanceToBottom
        }

        const afterRunUserFetchItems = () => {
            // Allow this function to run again
            fetching.value -= 1
            nextTick().then(() => {
                // Run it again right away if that is needed and nothing else is running at the same time
                if (moreItemsRequired() && fetching.value === 0)
                    runUserFetchItems()
            })
        }

        let errorCount = 0
        const runUserFetchItems = (itemsToLoad, reset) => {
            if (errorCount > 10) return
            if (props.itemsAtEnd && !reset) return

            // Enter the point that can only run once at a time
            fetching.value += 1

            // Call the user function with the number of items to fetch and the items that have already
            // been fetched as the second argument.
            props.fetchItems(itemsToLoad ?? 12, reset ?? false)
                .then(() => {
                    errorCount = 0
                    afterRunUserFetchItems()
                }, e => {
                    console.error("User provided fetch function in the infinite scroller component threw an error.", e)
                    errorCount += 1
                    afterRunUserFetchItems()
                })
        }

        // Get some data to start the infinite scroller and check if we need more every time the items change
        runUserFetchItems()

        // Detect when the user has scrolled down enough so that we need to load new items
        const onScroll = () => {
            if (moreItemsRequired() && fetching.value === 0)
                runUserFetchItems()
        }
        onMounted(() => window.addEventListener("scroll", onScroll))
        onUnmounted(() => window.removeEventListener("scroll", onScroll))

        return { fetching, infiniteScrollBottomDetector, runUserFetchItems }
    }
}
</script>
