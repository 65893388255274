<template>
    <!-- Sorting -->
    <div class="pr-5 w-full">
        <div class="flex text-sm justify-between  rounded-md  mt-2 border-2 border-transparent">
            <label class="text-base text-gray-500 dark:text-white font-medium">Sorting</label>
            <!-- <font-awesome-icon
                icon="fa-solid fa-xmark"
                class="cursor-pointer place-self-center text-gray-500 dark:text-gray-300 px-1 border-2 rounded-md border-transparent hover:text-gray-700 dark-hover:text-gray-100"
                fixed-width
                size="xs"
                @click="$emit('update:sortVal', sorting[0].outKey); emitSortUpdate(sorting[0].outKey)" /> -->
    <!-- TODO:  SelectInput needs to be styled more like Multiselect. -->
        </div>
    <SelectInput heading="" placeholder="" :options="sortOptions" :value="sortVal"
        @update:value="$emit('update:sortVal', $event); emitSortUpdate($event)"/>
    </div>
    <div class="flex flex-row text-gray-500 dark:text-white pt-2 text-base font-medium">
        <p>Filters</p>
    </div >
    <!-- Expand/Collapse all -->
    <div @click="toggleAll" class="cursor-pointer flex flex-row border-b border-gray-300 text-gray-500 dark:text-white mr-6 py-3 text-sm font-medium">
        <div
                class="flex place-self-center text-gray-500 transition"
                :class="[!collapsed ? 'transform rotate-90' : '']" >
                <font-awesome-icon icon="fa-solid fa-angles-right"
                    class=" text-gray-500 dark:text-white"
                    fixed-width
                    size="xs"
                    />
            </div>
        <p class="pl-2">{{ collapsed ? 'Expand' : 'Collapse' }} all</p>
    </div>
    <div v-for="filters, category, idx in filtersByCategory" :key="idx" class="my-0">
        <div class="cursor-pointer flex flex-row border-b border-gray-300 text-gray-500 dark:text-white mr-6 py-3 text-sm font-medium"  @click="showFilter[category] = !showFilter[category]">
            <!-- Minimize category -->
            <div
                class="flex place-self-center text-gray-500 transition"
                :class="[showFilter[category] ? 'transform rotate-90' : '']" >
                <font-awesome-icon icon="fa-solid fa-chevron-right"
                    class=" text-gray-500 dark:text-white"
                    fixed-width
                    size="xs"
                    />
            </div>
            <div class="flex pl-2 justify-between w-full">
                <!-- Name of category -->
                <p class="">{{ category }}</p>
                <!-- Clear Filter -->
                <font-awesome-icon
                    icon="fa-solid fa-xmark"
                    class="cursor-pointer place-self-center text-gray-500 dark:text-gray-300 px-1 border-2 rounded-md border-transparent hover:text-gray-700 dark-hover:text-gray-100"
                    fixed-width
                    size="xs"
                    @click.stop.prevent="clearAllFiltersInCategory(category)" />
            </div>
        </div>
        <div v-show="showFilter[category]" class="py-3 pr-5">
            <div v-for="filter in filters" :key="filter.key" class="">

                <!-- Filter label -->
                <div class="flex justify-between border-2 rounded-md border-transparent text-sm">
                    <label class="text-gray-500 dark:text-white">{{ filter.label }}</label>
                    <font-awesome-icon
                        icon="fa-solid fa-xmark"
                        class="cursor-pointer place-self-center text-gray-500 px-2 hover:text-gray-700 dark:text-gray-300 dark-hover:text-gray-100"
                        fixed-width
                        size="xs"
                        @click="selectedFilters[filter.key] = []; emitFilterUpdate()" />
                </div>

                <!-- Handling for different field types -->
                <Multiselect v-if="filter.type === 'Dropdown'" :modelValue="selectedFilters[filter.key]" :options="filter.options" label=""
                    @update:modelValue="selectedFilters[filter.key] = $event; emitFilterUpdate()" />
                <SmallTextInput v-else-if="filter.type === 'Textbox'" :value="selectedFilters[filter.key]?.[0] ?? ''"
                    @update:value="selectedFilters[filter.key] = [$event]; emitFilterUpdate()" />
                <SmallIntInput v-else-if="filter.type === 'Int'" :value="selectedFilters[filter.key]?.[0] ?? ''"
                    @update:value="selectedFilters[filter.key] = [$event]; emitFilterUpdate()" />
                <SelectInput v-else-if="filter.type === 'Bool'" heading="" placeholder="" :options="{'Both': '', 'Yes': 'true', 'No': 'false'}"
                    :value="selectedFilters[filter.key]?.[0] ?? ''"
                    @update:value="selectedFilters[filter.key] = [$event]; emitFilterUpdate()" />
                <RangeSliderInput v-else-if="filter.type === 'NumRange'"
                    :value="selectedFilters[filter.key].length > 0 ? selectedFilters[filter.key][0].split('-') : [filter.options[0], filter.options[1]]"
                    @onChange="selectedFilters[filter.key] = [$event.join('-')]; emitFilterUpdate()"
                    :min="filter.options[0]" :max="filter.options[1]" />
                <div v-else-if="filter.type === 'Unknown'">
                    <input class="w-full rounded-md border-gray-300 border-2" type="text" placeholder="Field Disabled" disabled />
                </div>
                <p v-else>Unknown filter type "{{ filter.type }}" Please contact Showdeck's developers to fix the issue.</p>

                <!-- Include/exclude -->
                <div v-if="filter.includeExclude" class="flex flex-row text-gray-500 dark:text-gray-300">
                    <div class="justify-center">
                        <input class="text-gray-500 focus:ring-0" :name="`filter-include-exclude-${filter.key}`"
                            :id="`filter-include-${filter.key}`" type="radio"
                            v-model="filtersOnInclude[filter.key]" :value="true"
                            @change="emitFilterUpdate()" />
                        <label class="px-2 text-sm" :for="`filter-include-${filter.key}`">Include</label>
                    </div>
                    <div class="justify-center">
                        <input class="text-gray-500 focus:ring-0" :name="`filter-include-exclude-${filter.key}`"
                            :id="`filter-exclude-${filter.key}`" type="radio"
                            v-model="filtersOnInclude[filter.key]" :value="false"
                            @change="emitFilterUpdate()" />
                        <label class="pl-2 text-sm" :for="`filter-exclude-${filter.key}`">Exclude</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, ref, watchEffect } from "vue"
import Multiselect from "../../inputs/Multiselect.vue"
import SmallTextInput from "../../inputs/SmallTextInput.vue"
import RangeSliderInput from "../../inputs/RangeSliderInput.vue"
import SelectInput from "../../inputs/SelectInput.vue"
import SmallIntInput from "../../inputs/SmallIntInput.vue"
import match from "@/utils/Match"

const getFiltersByCategory = filters => {
    const byCategoryMap = new Map()
    for (const filter of filters) {
        const catList = byCategoryMap.get(filter.category)
        if (catList === undefined)
            byCategoryMap.set(filter.category, [filter])
        else catList.push(filter)
    }

    return Object.fromEntries(byCategoryMap)
}

export default {
    name: "CategoryTableFilters",
    components: { Multiselect, SmallTextInput, RangeSliderInput, SelectInput, SmallIntInput },
    props: {
        filters: Array,
        sorting: Array,
    },
    emits: [
        "filterUpdate",
        "sortUpdate",
    ],
    setup(props, { emit }) {
        const selectedFilters = ref({})
        const filtersOnInclude = ref({})
        const showFilter = ref({})

        const filtersByCategory = computed(() => getFiltersByCategory(props.filters))
        const collapsed = computed(() => Object.values(showFilter.value).every(item => item === false))

        // Make sure all the filter keys exist in the selected filters list
        watchEffect(() => {
            const requiredKeys = props.filters.map(filter => filter.key)

            // Add the new keys that aren't in selectedFilters yet
            const currentKeys = new Set(Object.keys(selectedFilters.value))
            const missingKeys = requiredKeys.filter(key => !currentKeys.has(key))
            console.log("Adding missing keys: ", missingKeys)
            missingKeys.forEach(key => {
                selectedFilters.value[key] = []
                filtersOnInclude.value[key] = true
            })
        })

        const emitFilterUpdate = () =>
            emit("filterUpdate", selectedFilters.value, filtersOnInclude.value)
        const emitSortUpdate = newSortParam => emit("sortUpdate", newSortParam)

        return {
            // Refs
            selectedFilters,
            filtersOnInclude,
            showFilter,
            sortVal: ref(props.sorting?.[0]?.outKey ?? ""),

            // Computed values
            collapsed,
            filtersByCategory,
            sortOptions: computed(() => {
                const allEntries = []

                // Go through each sorting option and generate its entries based on the props sorting
                // options
                for (const sort of props.sorting) {
                    const newEntries = match(sort.ordering, {
                        "Alphabetical": () => [
                            [`${sort.label} A to Z`, `${sort.outKey}`],
                            [`${sort.label} Z to A`, `-${sort.outKey}`]
                        ],
                        "Numerical": () => [
                            [`${sort.label} Low to high`, `${sort.outKey}`],
                            [`${sort.label} High to low`, `-${sort.outKey}`]
                        ],
                        "None": () => [[`${sort.label}`, sort.outKey]],
                    })
                    allEntries.push(...newEntries)
                }

                return Object.fromEntries(allEntries)
            }),

            // Functions
            clearAllFiltersInCategory: category => {
                // Update the selected filters
                const keysToRemove = props.filters
                    .filter(filter => filter.category === category)
                    .map(filter => filter.key)
                keysToRemove.forEach(key => {
                    selectedFilters[key] = []
                    filtersOnInclude[key] = true
                })

                // Notify the controller of the change
                emitFilterUpdate()
            },
            toggleAll: () => {
                let expand = collapsed.value
                const categoryNames = Object.keys(filtersByCategory.value)
                categoryNames.forEach(item => {
                    showFilter.value[item] = expand
                })
            },
            emitFilterUpdate,
            emitSortUpdate,
        }
    }
}
</script>
