export default (value, handlingFuncs) => {
    const keys = Object.keys(handlingFuncs)

    const eqFunc = item => item === value
    const handingFuncKey = keys.find(eqFunc)

    if (handingFuncKey === undefined) {
        const defaultHandlingFunc = handlingFuncs._
        if (defaultHandlingFunc !== undefined) return defaultHandlingFunc(value)
        throw new Error(`No handling function provided that could handle the value "${value}"`)
    }
    else return handlingFuncs[handingFuncKey](value)
}