<template>
<div>
    <!-- Top bar -->
    <div class="shadow sticky top-0  flex flex-row border-t-2 border-gray-300 justify-between lg:px-9 px-7 py-1 bg-primary dark:bg-primaryDark ">
        <div class="flex flex-row gap-2 max-w-max flex-wrap self-center text-xs text-gray-500 dark:text-white font-medium">
            <router-link :to="{ name: 'Home' }">
                <font-awesome-icon icon="fa-solid fa-house" class="self-center hover:text-gray-700 dark-hover:text-gray-400" size="xs" />
            </router-link>
            <template v-for="pathPart, idx in path" :key="idx">
                <font-awesome-icon icon="fa-solid fa-chevron-right" class="self-center" size="xs"/>
                <a class="cursor-pointer" @click="pathPart.goto()">
                    <p class="hover:text-gray-700 dark-hover:text-gray-400">{{ pathPart.label }}</p>
                </a>
            </template>
        </div>
        <div class="self-center h-10">
            <!-- AddButton (or similar) gets put here by parent component-->
            <slot>

            </slot>
        </div>
    </div>
    <div class="grid grid-cols-8 gap-3 mt-3 sm:mx-10 ">
        <!-- Desktop Filter Section -->
        <div v-if="!smallScreen" v-show="filtersVisible" class="lg:col-span-2 sm:col-span-3 col-span-4">
            <div class="pl-5 pt-2 rounded-md bg-primary pb-5 dark:bg-primaryDark">
                <CategoryTableFilters :filters="filters" :sorting="sorting"
                    @filterUpdate="filterUpdate" @sortUpdate="sortUpdate" />
            </div>
        </div>

        <!-- Main content with items -->
        <div :class="`lg:col-span-${filtersVisible ? '6' : '8'} sm:col-span-${filtersVisible ? '5' : '8'} p-0 col-span-8`">
            <div class="bg-primary dark:bg-primaryDark rounded-md ">
            <!-- Options -->
            <div class="flex flex-row justify-between pt-5 bg-primary dark:bg-primaryDark pb-2 rounded-md px-5 gap-5"
                :class="hasTopBar ? 'top-28' : 'top-32'"
            >
                <!-- Search -->
                <div class="w-full">
                    <input v-model="searchString" type="text" placeholder="Search..." class="rounded-md z-10 focus-within:hidden pr-8 md:w-full w-2/3 border-gray-300 dark:bg-primaryDark dark:text-white"/>
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass z-0 self-center"
                            class="text-gray-300 -ml-8"
                            fixed-width
                        />
                </div>
                <!-- View method and Filter Toggle -->
                <div class="flex items-end">
                    <div class="grid grid-flow-col rounded-md border border-gray-300 h-full place-items-center bg-primary dark:bg-primaryDark">
                        <!-- View as Photo Grid -->
                        <div class="p-2 justify-center">
                            <ViewGridIcon class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200" @click="tableViewActive = false" :class="!tableViewActive ? 'text-gray-900 dark:text-gray-100' : 'text-gray-400'" />
                        </div>
                        <!-- View as Table -->
                        <div class="border-l-2 border-gray-500 px-2  py-1 justify-center">
                            <ViewListIcon class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200" @click="tableViewActive = true" :class="tableViewActive ? 'text-gray-900 dark:text-gray-100' : 'text-gray-400'"/>
                        </div>
                        <!-- Toggle Filters -->
                        <div class="p-2 py-1 justify-center border-l-2 border-gray-500">
                            <FilterIcon v-if="!smallScreen" class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200" @click="filtersVisible = !filtersVisible" :class="filtersVisible ? 'text-gray-600 dark:text-gray-200' : 'text-gray-400 dark:text-gray-100'"/>
                            <FilterIcon v-else class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200" @click="mobileFiltersVisible = !mobileFiltersVisible" :class="mobileFiltersVisible ? 'text-gray-600 dark:text-gray-200' : 'text-gray-400 dark:text-gray-100'"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Box/Table view -->
            <!-- Design needs to wait for figma to be completed -->
            <div v-if="tableViewActive" class="mt-5">
                <!-- Table header -->
                <div class="flex-grow grid grid-flow-col auto-cols-fr shadow-md font-medium text-gray-500 bg-gray-50 dark:bg-primaryDark dark:text-gray-300 sm:px-5 px-1 sm:mx-5">
                    <!--    This empty div is to make the table headers
                            align with the columns.
                    -->
                    <div class="col-span-2 p-1"/>
                    <p v-for="col in tableColumns" :key="col.key" class="p-1 flex items-center"
                        :class="`col-span-${col.size ?? 1}`"
                        >
                        {{ col.label }}
                    </p>
                </div>
            </div>
            <InfiniteScroller ref="boxInfScroll" :containerClass="tableViewActive ? 'mb-5' : filtersVisible ? 'grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-5 mt-5 p-5' : 'grid lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-2 gap-5 mt-5 p-5'"
                :items="items" :fetchItems="fetchItems" :itemsAtEnd="itemsAtEnd" v-slot="{ item, idx }">
                <!-- Navigate to Login page if user is not logged in -->
                <router-link :to="isLoggedIn ? item.to : {name: 'Login'}">
                <!-- Table content -->
                <div v-if="tableViewActive" class="sm:mx-5 sm:text-base text-sm"
                    :class="`cursor-pointer hover:bg-gray-100 dark-hover:bg-gray-700 text-gray-700 dark:text-white shadow-md flex flex-row sm:px-5 px-1 ${idx % 2 === 0 ? 'bg-white dark:bg-primaryDark' : 'bg-gray-50 dark:bg-gray-800'}`">
                    <div class="flex-grow grid grid-flow-col auto-cols-fr min-h-20">
                        <div class="col-span-2 aspect-square object-cover justify-self-center sm:p-1 self-center">
                            <img :src="item.image" :alt="item.imageAlt" class="w-auto h-auto  max-h-20 object-cover rounded-lg">
                        </div>
                        <div v-for="col in tableColumns" :key="col.key"
                            class=" p-1 flex flex-col self-center whitespace-pre truncate"
                            :style="`grid-column: span ${col.size ?? 1} / span ${col.size ?? 1}`">
                            <!-- Key -->
                            <div class="font-medium whitespace-pre truncate">
                                <a v-if="col.keyType === 'email'" :href="'mailto:' + item[col.key]" @click.stop>
                                {{ item[col.key] || " " }}
                                </a>
                                <a v-else-if="col.keyType === 'phone'" :href="'tel:' + item[col.key]" @click.stop>
                                    {{ item[col.key] || " " }}
                                </a>
                                <p v-else >
                                    {{ item[col.key] || " " }}
                                </p>
                            </div>
                            <!-- Subkey -->
                            <div class="text-gray-500 whitespace-pre truncate">
                                <a v-if="col.subkeyType === 'email'" :href="'mailto:' + item[col.subkey]" @click.stop>
                                {{ item[col.subkey] || " " }}
                                </a>
                                <a v-else-if="col.subkeyType === 'phone'" :href="'tel:' + item[col.subkey]" @click.stop>
                                    {{ item[col.subkey] || " " }}
                                </a>
                                <p v-else>
                                    {{ item[col.subkey] || " " }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Grid View -->
                <div v-else class="group flex flex-col rounded-lg p-2">
                    <img :src="item.image" :alt="item.imageAlt" class="w-full h-auto aspect-square object-cover rounded-lg hover:shadow-md cursor-pointer">
                    <div class="">
                        <div class="flex flex-row justify-between p-1 w-full md:text-base text-sm">
                            <p class="line-clamp-3 font-medium cursor-pointer hover:text-gray-700 dark:text-white">{{ item.title }}</p>
                            <!--    Favorite button
                                    NOTE:   This currently has no functionality.
                                            Refreshing the page will reset your favorites.
                            -->
                            <FavoriteButton v-if="isLoggedIn" :item="item" :favorites="favorites" :addFavorite="addFavorite"/>
                        </div>
                        <p class="line-clamp-3 text-gray-500 dark:text-gray-400 px-1">{{ item.subtitle }}</p>
                    </div>
                </div>
                </router-link>
            </InfiniteScroller>
            </div>
        </div>
    </div>
    <!-- Filters on Mobile -->
    <BottomSheet v-if="smallScreen" :active="mobileFiltersVisible" @close="mobileFiltersVisible = false" :filters="true">
        <div class="pl-5 bg-primary dark:bg-primaryDark rounded-md overflow-y-auto max-h-96">
            <CategoryTableFilters :filters="filters" :sorting="sorting"
                @filterUpdate="filterUpdate" @sortUpdate="sortUpdate" />
        </div>
    </BottomSheet>
</div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex';
import InfiniteScroller from '@/components/widgets/InfiniteScroller.vue';
import { ViewGridIcon, ViewListIcon, FilterIcon } from "@heroicons/vue/outline/esm";
// import AddButton from "@/components/inputs/AddButton";
// import Sidebar from '../../layout/Sidebar.vue';
import FavoriteButton from '../../inputs/FavoriteButton.vue';
import BottomSheet from '../bottomSheet/BottomSheet.vue';
import CategoryTableFilters from './CategoryTableFilters.vue';
import useBreakpoints from "@/utils/reactive/useBreakpoints"

export default {
    name: "CategoryTable",
    components: {
        InfiniteScroller,
        ViewGridIcon,
        ViewListIcon,
        FilterIcon,
        // AddButton,
        BottomSheet,
        FavoriteButton,
        CategoryTableFilters,
    },
    props: {
        /**
        * [ { title: String, subtitle: String, image: String, to: Object } ]:
        * An array of the items that will be displayed to the user as the items on the current page. Each
        * item has a title, image and subtitle, these are required on all items. They then need to have
        * extra properties for each tableColumn specified with that parameter as those values may be
        * fetched at any time by the user if they switch to table view.
        *
        * Items can also include a to item that will be passed to router-link as the to property to
        * link from each item to a new page. This is the same kind of object as router-link and
        * router.push expect: https://router.vuejs.org/api/#to */
        items: Array,
        /**
         * Function: A function that can be called to request the items array to be enlarged by the
         * controller.
         *
         * The first argument to this function is the number of items you want to be added to the
         * items array, the second argument is a boolean allows you to specify if you want the items
         * array to also be reset before inserting the new items. This is for when filters have
         * changed and the current things in the items array might thus not belong there anymore.
         */
        fetchItems: Function,
        itemsAtEnd: Boolean,
        /**
         * [ { label: String, goto: Function } ]:
         * An array of path locations, displayed at the top so the user knows where they are and can easily
         * navigate back to larger pages. */
        path: Array,
        /**
         * [ { label: String, key: String, keyType: String, subkey: String, subkeyType: String, size: Number } ]:
         * An array of all the columns that will be shown in the table view. All items need to have
         * properties with the names of all keys of tableColumns as they may be used at any time if the
         * user enters table view mode.
         *
         * keyType and subkeyType are optional and can support either the value 'email' or 'phone'.
         * In the case of either 'email' or 'phone' the appropriate <a/> element will be rendered.
         * If no key types are supplied (or a key type that doesn't match those outlined above)
         * a simple <p/> element will be rendered.
         *
         * size is used to determine the width of the column, i.e. how many columns of the parent
         * grid should this table column span.
         * */
        tableColumns: Array,
        /**
         * [ { label: String, key: String, includeExclude: Boolean, category: String,
         *     type: Enum[ Dropdown, Textbox, DateRange, Range, NumRange ],
         *     options: [ { label: String, image: String, key: String } ] } ]:
         * An array of all the filters that are available to filter the data.
         *
         * Each option can also be a string, in that case the string will serve as both the label.
         *
         * A filter of type Dropdown can have the full options, Textbox has no options, Range
         * expects a option for each value on the slider but doesn't support images and then the
         * rest expects options to be provided as a list of strings or numbers. The DateRange can
         * have two options, a from and to date that the date pickers must stay within and the
         * NumRange expects two options that the value picked must stay within.
         * and the key. */
        filters: Array,
        /**
         * [ { label: String, key: String, outKey: String, ordering: Enum [ Alphabetical, Numerical, None ] } ]:
         * An array of all the available sorting methods.
         *
         * To allow the sorting to work with the table columns, use the same key on the sorting
         * option and the tableColumn and the table column will then be able to change that sorting
         * option. Because of this the outKey can be used to specify what will go to the backend/db
         * without affecting the linkage with the tableColumn. */
        sorting: Array,
    },
    emits: [
        /**
         * @arg1 String - The outKey supplied in the sorting property, should be sorted in reverse
         *                if it starts with a minus "-"
         */
        "sortUpdate",
        /**
         * @arg1 dict<String, [ String ]> - The selected filters dict
         * @arg2 dict<String, Boolean>    - A dictionary showing which filters set to
         *                                  include/exclude, true if they are on include (default)
         *                                  and false otherwise.
         */
        "filterUpdate",
        /**
         * @arg1 String - the new search string
         */
        "searchUpdate",
    ],
    setup(props, { emit }) {
        const store = useStore()
        const { width } = useBreakpoints()

        const filtersVisible = ref(true)
        const mobileFiltersVisible = ref(false)
        const tableViewActive = ref(false)

        const sideBarActive = ref(false)
        // NOTE: This is a dummy ref for the fav button
        const favorites = ref([])

        const searchString = ref("")
        watch(searchString, () => emit("searchUpdate", searchString.value))

        const boxInfScroll = ref("")


        const isLoggedIn = computed(() => store.getters.isLoggedIn) // Used to decide to show favorite button or not

        return {
            filtersVisible,
            mobileFiltersVisible,
            tableViewActive,
            sideBarActive,

            searchString,

            boxInfScroll,

            screenWidth: width,
            smallScreen: computed(() => {
                    return width.value < 640
                }
            ),

            // NOTE: This is a dummy ref for the fav button
            favorites,

            isLoggedIn,

            filterUpdate: (arg1, arg2) => emit("filterUpdate", arg1, arg2),
            sortUpdate: arg1 => emit("sortUpdate", arg1),
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        runFetchItems(numItemsToLoad, reset) {
            return this.boxInfScroll.runUserFetchItems(numItemsToLoad, reset)
        },
        addFavorite(favorites, item) {
            if (favorites.some(fav => fav.id === item.id)) {
                const index = favorites.indexOf(item)
                favorites.splice(index, 1)
            } else {
                favorites.push(item)
            }
        },
        handleResize() {
            this.screenWidth = window.innerWidth
        },
        heartHover(index) {
            this.currentHover = index
        }
    },
    computed: {
        //  hasTopBar is used to determine the y position of where options should stick
        hasTopBar: function () {
            return this.$route.meta.hasTopBar ?? false
        },
  }
}
</script>

<style>
/* Manually implement the aspect ratio tailwind v3 feature until we upgrade from v2 to v3 */
.aspect-auto { aspect-ratio: auto; }
.aspect-square { aspect-ratio: 1 / 1; }
.aspect-video { aspect-ratio: 16 / 9; }
</style>
